import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import SlickSlider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styled from 'styled-components';
import Buttons from '../components/Buttons';
import SwipeIndicator from '../components/SwipeIndicator';

const NewsContainer = styled.div`
    width: ${props => props.theme.scale * 918}px;
    height: 100%;
    position: relative;
    overflow-y: scroll;
`;

const SwiperItem = styled.div`
    background-color: ${props => props.theme.shade3};
    ${(props) => props.theme.customer === 'publismart' ? `height: ${props.theme.scale * 1456}px;` : `height: ${props.theme.scale * 1048}px; `}

    .swiper-slide__container {
        width: 100%;         
        overflow: hidden;
    }
    &:focus {
        outline: none;
    } 
`;

const StyledSlickSlider = styled(SlickSlider)`
    ${(props) => props.theme.customer === 'publismart' ? `height: ${props.theme.scale * 1456}px;` : `height: ${props.theme.scale * 1048}px; `}

    &:focus {
        outline: none;
    } 
    .slick-list, .slick-track {
        height: 100%;
    }
    .slick-slide {
        width: 100%;
        height: 100%;
    }
    .slick-prev {
        top: auto;
        bottom: ${props => props.theme.scale * 140}px;
        left: ${props => props.theme.scale * 360}px;
    }
    .slick-next {
        top: auto;
        bottom: ${props => props.theme.scale * 140}px;
        right: ${props => props.theme.scale * 360}px;
    }
`;


const NewsImageWrapper = styled.div`
    height: ${props => props.theme.scale * 516}px;
    overflow: hidden;
`;

const NewsImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const NewsDetail = styled.div`
    position: relative;
    text-align: left;
    padding: ${props => props.theme.scale * 40}px;
`;

const NewsVideo = styled.video`
    width: 100%;
`;

const NewsTitle = styled.div`
    font-size: ${props => props.theme.scale * 38}px;
    font-family: 'MuseoSans-900';
    color: ${props => props.theme.white};
`;

const NewsHeader = styled.div`
    font-size: ${props => props.theme.scale * 26}px;
    font-family: 'MuseoSans-300';
    margin: ${props => props.theme.scale * 20}px 0;
    color: ${props => props.theme.white};
`;

const NewsHead = styled.div`
    font-size: ${props => props.theme.scale * 60}px;
    font-family: 'MuseoSans-900';
    padding: ${props => props.theme.scale * 40}px ${props => props.theme.scale * 30}px;
`;

const NewsItem = styled.div`
    background: ${props => props.theme.white};
`;

const NewsItemTitle = styled.div`
    color: ${props => props.theme.black};
    font-size: ${props => props.theme.scale * 38}px;
    font-family: 'MuseoSans-900';
`;

const SwipeIndicatorWrapper = styled(SwipeIndicator)`
    position: absolute;
    top: ${props => props.theme.scale * 700}px;
    left: ${props => props.theme.scale * 420}px;
`;


const HorizontalSlider = () => {
    const customer = useSelector(state => state.config.customer);
    const news = useSelector(state => state.data.news);
    const [mySlider, setMySlider] = useState(undefined);

    if (!news || news.error) return(null);

    const params = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        afterChange: function(index) {
            let video = document.querySelector('div[data-index="'+index+'"] video');
            if (video) {
                video.currentTime = 0;
                video.play();
    
                video.onended = function() {
                    mySlider.slickGoTo(index+1);
                };
            }
        },
        beforeChange: function(index) {
            let video = document.querySelector('div[data-index="'+index+'"] video');
            if (video) {
                video.currentTime = 0;
                video.pause();
            }
        }

    }

    let slides = [];

    const newsDetail = (item,i) => {
        let output = "";

        if (item.type === "video") {
            output = <SwiperItem key={i}>
                    <div className="swiper-slide__container" id={'video' + i}> 
                        <NewsImageWrapper>
                            <NewsVideo autoplay muted>
                                <source src={item.media} type="video/mp4" />
                            </NewsVideo>
                        </NewsImageWrapper>
                        <NewsDetail>
                            <NewsTitle>{item.title}</NewsTitle>
                                <NewsHeader
                                    dangerouslySetInnerHTML={{
                                        __html: (item.description === null ? '' : item.description.replace(/<a href="(.*?)">/g,"").replace(/<\/a>/g,"").replace(/<a role="link" href="(.*?)"/g, "")),
                                    }}
                                ></NewsHeader>
                        </NewsDetail>                    
                    </div>
                </SwiperItem>;
        } else if (item.type === "picture") {
            output = <SwiperItem key={i}>
                    <div className="swiper-slide__container">
                        <NewsImageWrapper><NewsImage src={item.media} /></NewsImageWrapper>
                        <NewsDetail>
                            <NewsTitle>{item.title}</NewsTitle>
                                <NewsHeader
                                    dangerouslySetInnerHTML={{
                                        __html: (item.description === null ? '' : item.description.replace(/<a href="(.*?)">/g,"").replace(/<\/a>/g,"").replace(/<a role="link" href="(.*?)"/g, "")),
                                    }}
                                ></NewsHeader>
                        </NewsDetail>                    
                    </div>
                </SwiperItem>;
        }
        
        if (mySlider) {
            let video = document.querySelector('div[data-index="0"] video');
            if (video) {
                video.currentTime = 0;
                video.play();

                video.onended = function() {
                    mySlider.slickGoTo(1);
                };
            }
        }

        
        return output;
    };

    if (news) {
        slides = news.map((item,i) => { return newsDetail(item,i) }, [SwiperItem]);
    }

    return (
        <NewsContainer>
            <NewsHead>
                Toute l'actualité<br/>de votre Commune
            </NewsHead>
            <StyledSlickSlider ref={slider => (setMySlider(slider))} {...params}>
                {slides}
            </StyledSlickSlider>
            {customer !== 'publismart' && <Buttons type="small" />}
        </NewsContainer>
    );
}

const VertikalSlider = () => {
    const news = useSelector(state => state.data.news);
    const [swipeIndicator, setSwipeIndicator] = useState(true);

    if (!news) return(null);

    const hideSwiper = () => { 
        setSwipeIndicator(false);
    }

    return (
        <NewsContainer onMouseDown={hideSwiper} onTouchStart={hideSwiper} onScroll={hideSwiper}>
            <NewsHead>
                Toute l'actualité<br/>de votre Commune
            </NewsHead>
            {news.map((item, i) => 
                <NewsItem>
                    <NewsImageWrapper>
                        {item.type === 'video' ? 
                            <NewsVideo autoplay muted>
                                <source src={item.media} type="video/mp4" />
                            </NewsVideo>    
                            :
                            <NewsImage src={item.media} />
                        }
                    </NewsImageWrapper>
                    <NewsDetail>
                        <NewsItemTitle>{item.title}</NewsItemTitle>
                            <NewsHeader
                                dangerouslySetInnerHTML={{
                                    __html: (item.description === null ? '' : item.description.replace(/<a href="(.*?)">/g,"").replace(/<\/a>/g,"").replace(/<a role="link" href="(.*?)"/g, "")),
                                }}
                            ></NewsHeader>
                    </NewsDetail>
                </NewsItem>
            )}
            {swipeIndicator && <SwipeIndicatorWrapper direction="v" />}  
        </NewsContainer>
    );
}

const News = () => {
    const customer = useSelector(state => state.config.customer);

    if (customer === 'publismart') 
        return <VertikalSlider />;
    else 
        return <HorizontalSlider />;
}

export default News;
