import React from 'react';
import Marquee from 'react-double-marquee';
import {connect} from 'react-redux';
import {
    BrowserRouter as Router,
    Navigate, Route,
    Routes
} from "react-router-dom";
import styled, {ThemeProvider} from 'styled-components';
import Header from '../components/Header';
import InactivityCheck from '../components/InactivityCheck';
import Nav from '../components/Nav';
import {
    setCustomer
} from '../redux/actions';
import Agenda from './Agenda';
import Home from './Home';
import Mobilite from './Mobilite';
import News from './News';
import Plan from './Plan';

// Styled Components
const MainWrapper = styled.div`
    background: ${props => props.theme.white};
    width: ${props => props.theme.width * props.theme.scale}px;
    height: ${props => props.theme.height * props.theme.scale}px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    position: relative;
`;

const ErrorWrapper = styled.div`
    background: ${props => props.theme.white};
    width: ${props => props.theme.width * props.theme.scale}px;
    height: ${props => props.theme.height * props.theme.scale}px;
    display: flex;
    margin: 0 auto;
    position: relative;
    justify-content: center;
    text-align: center;
    align-items: center;
`;

const Headline = styled.div`
    width: 100%;
    height: ${props => props.theme.scale * 73}px;
    background-color: ${props => props.theme.shade2};
    overflow: hidden;
    position: relative;
    color: ${props => props.theme.white};
    font-size: ${props => props.theme.scale * 30}px;
    font-family: 'MuseoSans-900';
    padding-top: ${props => props.theme.scale * 18}px;
    box-sizing: border-box;
    white-space: nowrap;
`;

const Container = styled.div`
    width: 100%;
    height: ${props => props.theme.scale * 1632}px;
    display: flex;
    flex-direction: row;
`;

const Content = styled.div`
    overflow: hidden;
    width: 100%;
    height: 100%;
`;

class PubliSmart extends React.Component {
    constructor(props) {
        super(props);

        const { dispatch } = this.props
        const myStorage = window.localStorage;
        const urlParams = new URLSearchParams(window.location.search);
        const isLocal = urlParams.get('local');

        let myCustomer = urlParams.get('customer');
        let myScreen = urlParams.get('screen');
        
        if (myCustomer) myStorage.setItem('customer', myCustomer);
        if (myScreen) myStorage.setItem('screen', myScreen);
        if (isLocal) myStorage.setItem('local', isLocal);
    

        dispatch(setCustomer(myStorage.getItem('customer'), myStorage.getItem('screen'), myStorage.getItem('local')));
        //dispatch(setLocal(myStorage.getItem('local')));
    }

    render() {
        
        console.log(document.documentElement.clientHeight)
        const { width, height, scale, theme, error, news, customer } = this.props;

        let colorTheme = 'light';

        const now = new Date();
        const hour = now.getHours();
        
        if (hour <= 7 || hour >= 17) {
            colorTheme = 'dark';
        }

        const themeSettings = {
            ...theme,
            scale: scale,
            width: width,
            height: height,
            customer: customer,
            colorTheme: colorTheme
        }

        if (error.number !== undefined) return (<ErrorWrapper><h1>{error.message}</h1></ErrorWrapper>)
        
        let marquee;
        if (news) {
            if (news.length > 0) {
                marquee = <Marquee direction="left" delay={0} childMargin={0}>
                    {news.map((item,i) => (
                        <span key={i}>{item.title} | </span>
                    ))}
                </Marquee>;
            }
        }

        return (
            <Router>
                <InactivityCheck gotopath={'/home'} />
                <ThemeProvider theme={themeSettings}>   
                    <MainWrapper className="publismart">
                        <Header />
                        <Headline>
                            {marquee}
                        </Headline>
                        <Container>
                            <Nav />
                            <Content>
                                <Routes>
                                    <Route path="/" element={<Navigate to="/home" />} />
                                    <Route path="/home" element={<Home />} />  
                                    <Route path="/commune" element={<News />} />
                                    <Route path="/mobilite" element={<Mobilite />} />  
                                    <Route path="/plan" element={<Plan />} />
                                    <Route path="/agenda" element={<Agenda />} /> 
                                </Routes>
                            </Content>
                        </Container>
                    </MainWrapper> 
                </ThemeProvider>
            </Router>
        )
    }
}
  
const mapStateToProps = (state) => ({
    width: state.config.width,
    height: state.config.height,
    scale: state.config.scale,
    theme: state.config.theme,
    error: state.config.error,
    customer: state.config.customer,
    news: state.data.news
})
  
  
export default connect(mapStateToProps)(PubliSmart);
  