import React, {useRef, useState} from "react";
import {useSelector} from "react-redux";
import SlickSlider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styled from "styled-components";
import videojs from "video.js";
import Buttons from "../components/Buttons";
import VideoJS from '../components/Player';

const HomeContainer = styled.div`
    width: 100%;
    height: 100%;
    background-color: black;
`;

const VideoPlayer = styled.video`
    width: 100%;
    height: 100%;
`;

const Ad = styled.div`
    width: 100%;
    height: 100%;
`;

const SwiperItem = styled.div`
    .swiper-slide__container {
        max-height: ${props => props.theme.scale * props.theme.contentHeight}px;          
        transition: max-height 0.5s;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        video {
            width: 100%;
        }
    }
`;

const StyledSlickSlider = styled(SlickSlider)`
    height: 100%;

    &:focus {
        outline: none;
    }
    .slick-list {
        padding: 0;
    }
`;



const Default = () => {
    const output = useSelector((state) => state.data.affiches);
    const maxRounds = useSelector((state) => state.data.maxRounds);
    const buttons = useSelector((state) => state.data.buttons);
    const [hideVideoplayer, setHideVideoplayer] = useState(false);
    
    if (!output || output.length === 0) return (
        <HomeContainer />
    );

    let currentClient = 0;
    let round = 0;

    // What to do when the video is done
    const videoEnded = (event) => {
        currentClient++;

        //console.log(currentClient + " : " + round);

        if (currentClient === output.length) {
            console.log("DONE");
            if (buttons && buttons.length > 0) {
                setHideVideoplayer(true);

                setTimeout(() => {
                    setHideVideoplayer(false);
                    currentClient = 0;
                    if (round < maxRounds - 1) round = round + 1;
                    else round = 0;
                }, 6000);
            } else {
                currentClient = 0;
                let newRound = 0;
                if (round < maxRounds - 1) newRound = round + 1;

                event.target.src = output[currentClient][newRound];
                event.target.play();

                round = newRound;
            }
        } else {
            event.target.src = output[currentClient][round];
            event.target.play();
        }
    };

    //console.log(currentClient + " : " + round);

    return (
        <HomeContainer>
            {!hideVideoplayer && (
                <VideoPlayer muted autoPlay onEnded={videoEnded}>
                    <source
                        src={output[currentClient][round]}
                        type="video/mp4"
                    />
                </VideoPlayer>
            )}
            {hideVideoplayer && <Buttons type="big" />}
        </HomeContainer>
    );
}

const Publismart = () => {
    const ads = useSelector((state) => state.data.affiches);
    const mySlider = useRef(undefined);
    const SliderTime = 10000;

    const params = {
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        fade: true,
        pauseOnHover: false,
        afterChange: function(index) {
            mySlider.current.autoplay = false;

            let video = document.querySelector('div[data-index="'+index+'"] .video-js');
            if (video) {
                let player = videojs(video);
                player.play();
                player.on('ended', () => {
                    mySlider.current.slickNext();
                });
            } else {
                setTimeout(() => { mySlider.current.slickGoTo(index+1); }, SliderTime);
            }
        
        },
        beforeChange: function(index, newIndex) {
            let video = document.querySelector('div[data-index="'+index+'"] .video-js');
            if (video) {
                let player = videojs(video);
                if (index !== newIndex) {
                    player.pause();
                } 
            }
        }
    };
   
    const playerRef = React.useRef(null);

    const handlePlayerReady = (player) => {
        playerRef.current = player;

        // you can handle player events here
        player.on('ended', () => {
            console.log('ENDED');
            mySlider.current.slickNext();
        });
    };

    const handleImgOnload = () => {
        setTimeout(() => { mySlider.current.slickGoTo(1); }, SliderTime);
    };

    let slides = [];
    
    if (ads) {
        slides = ads.map(
            (ad, i) => {
                if (ad.video)  {
                    let defaultOptions = { 
                        muted: true,
                        controls: false,
                        responsive: true,
                        preload: 'auto',
                        fluid: true,
                        aspectRatio: '9:16',
                        sources : [{
                            src: ad.video,
                            type: 'video/mp4'
                        }],
                        poster : ad.placeholderurl
                    };
    
                    let videoJsOptionsAuto = {...defaultOptions, autoplay: true};
                    let videoJsOptions = {...defaultOptions, autoplay: false};

                    return(
                        <SwiperItem key={i}>
                        <div className="swiper-slide__container" id={"video" + i}>
                            {i === 0 ? <VideoJS options={videoJsOptionsAuto} onReady={handlePlayerReady} key={i} /> : <VideoJS options={videoJsOptions} key={i} />}
                        </div>
                    </SwiperItem>
                    );
                } else {
                    return (
                        <SwiperItem key={i}>
                            {i === 0 ? <img src={ad.image} alt={ad.title} onLoad={handleImgOnload} /> : <img src={ad.image} alt={ad.title} />}
                        </SwiperItem>
                    )
                }
            },[SwiperItem]
        );
    }


    return (
        <HomeContainer>
            <Ad>
                <StyledSlickSlider ref={mySlider} {...params}>{slides}</StyledSlickSlider>
            </Ad>
        </HomeContainer>
    );


}

const Home = () => {
    const customer = useSelector((state) => state.config.customer);

    if (customer === 'publismart') return <Publismart/>;
    else return <Default/>;
};

export default React.memo(Home);
