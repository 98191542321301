import dayjs from 'dayjs';
import React, {useContext, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import styled, {ThemeContext} from 'styled-components';
import images from '../config/images';


const HeaderWrapper = styled.div`
    width: 100%;
    height: ${props => props.theme.scale * 215}px;
    background-color: ${props => props.theme.white};
    position: relative;

    ${props => (props.theme.colorTheme === 'dark') ? `background-color: ${props.theme.darkShade1};` : `background-color: ${props.theme.white};`}
`;

const Logo = styled.img`
    height: ${props => props.theme.scale * 140}px;
    max-width: ${props => props.theme.scale * 300}px;
    position: absolute;
    left: ${props => props.theme.scale * 40}px;
    top: ${props => props.theme.scale * 32}px;
`;

const Info = styled.div`
    position: absolute;
    right: ${props => props.theme.scale * 32}px;
    top: ${props => props.theme.scale * 32}px;
    display: flex;    
    font-size:  ${props => props.theme.scale * 26}px;
    ${props => (props.theme.colorTheme === 'dark') ? `color: ${props.theme.white};` : `color: ${props.theme.black};`}
`;

const Place = styled.div`
    font-family: 'MuseoSans-900';
    color: ${props => props.theme.place};
    text-transform: uppercase;
    margin-right: ${props => props.theme.scale * 20}px;
    font-size:  ${props => props.theme.scale * 24}px;
`;

const Date = styled.div`
    font-family: 'MuseoSans-300';
    margin-right: ${props => props.theme.scale * 10}px;
    font-size:  ${props => props.theme.scale * 26}px;
    ${props => (props.theme.colorTheme === 'dark') ? `color: ${props.theme.white};` : `color: ${props.theme.black};`}
`;

const Time = styled.div`
    font-family: 'MuseoSans-900';
    margin-left: ${props => props.theme.scale * 10}px;
    font-size: ${props => props.theme.scale * 26}px;
    ${props => (props.theme.colorTheme === 'dark') ? `color: ${props.theme.white};` : `color: ${props.theme.black};`}
`;

const Ligne = styled.div`
    position: absolute;
    bottom: ${props => props.theme.scale * 40}px;
    right: ${props => props.theme.scale * 260}px;
    text-align: right;
    ${props => (props.theme.colorTheme === 'dark') ? `color: ${props.theme.white};` : `color: ${props.theme.black};`}
`;

const LigneNumber = styled.div`
    font-family: 'MuseoSans-900';
    font-size: ${props => props.theme.scale * 46}px;
    ${props => (props.theme.colorTheme === 'dark') ? `color: ${props.theme.white};` : `color: ${props.theme.black};`}
`;

const LignePlace = styled.div`
    font-family: 'MuseoSans-300';
    font-size: ${props => props.theme.scale * 30}px;
    ${props => (props.theme.colorTheme === 'dark') ? `color: ${props.theme.white};` : `color: ${props.theme.black};`}
`;

const TimeUntil = styled.div`
    position: absolute;
    right: ${props => props.theme.scale * 30}px;
    bottom: ${props => props.theme.scale * 50}px;
    font-family: 'MuseoSans-900';
    font-size: ${props => props.theme.scale * 56}px;
    ${props => (props.theme.colorTheme === 'dark') ? `color: ${props.theme.white};` : `color: ${props.theme.black};`}
`;

const Error = styled.div`
    box-sizing: border-box;
    position: absolute;
    top: ${props => props.theme.scale * 100}px;
    right: ${props => props.theme.scale * 30}px;
    text-align: right;
    ${props => (props.theme.colorTheme === 'dark') ? `color: ${props.theme.white};` : `color: ${props.theme.black};`}
`;

const ErrorTitle = styled.div`
    font-size: ${props => props.theme.scale * 34}px;
    font-family: 'MuseoSans-900';
    ${props => (props.theme.colorTheme === 'dark') ? `color: ${props.theme.white};` : `color: ${props.theme.black};`}
`;

const ErrorUrl = styled.div`
    font-size: ${props => props.theme.scale * 30}px;
    font-family: 'MuseoSans-300';
    ${props => (props.theme.colorTheme === 'dark') ? `color: ${props.theme.white};` : `color: ${props.theme.black};`}
`;

const pad = (num, size) => {
    var s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
}

const Header = () => {
    const customer = useSelector(state => state.config.customer);
    const location = useSelector(state => state.config.location);
    const mobilite = useSelector(state => state.data.mobilite);
    const themeContext = useContext(ThemeContext)
    const [time, setTime] = useState(dayjs().format('HH:mm'));
    const [date, setDate] = useState(dayjs().format('DD.MM.YYYY'));

    useEffect(() => {
        let interval = null;
        
        interval = setInterval(() => {
            let newTime = dayjs().format('HH:mm');
            let newDate = dayjs().format('DD.MM.YYYY');
            setTime(newTime);
            setDate(newDate);
        }, 1000);
        
        return () => clearInterval(interval);
    }, [time, date]);

    if (!location) return(null);
    //if (!mobilite) return(null);

    let logo = images.logos[customer];
    if (themeContext.colorTheme === 'dark') {
        logo = images.logosDark[customer];
    }
    
    let bus;

    if (mobilite) {
        if (mobilite.departures) {
            if (mobilite.departures.length > 0) {

                for (let index = 0; index < mobilite.departures.length; index++) {
                    let cur = dayjs(`${mobilite.departures[index].date} ${mobilite.departures[index].time}`);
                    if (cur.diff(dayjs(),'minute', true) > 0) {
                        bus = mobilite.departures[index];
                        break;
                    }   
                }

                if (bus) {
                    console.log(bus);
                    let time = dayjs(`${bus.date} ${bus.time}`);
                    //let time = dayjs(testTime);
                    let now = dayjs();
    
                    //console.log(time);
                    //console.log(now);
            
                    let diff = time.diff(now,'minute', true);
    
                    //console.log(diff);
            
                    if (diff > 0) bus.until = pad(Math.ceil(diff), 2);
                    else bus.until = "00";
                }
                
            }
        }
    }

    return(
        <HeaderWrapper>
            <Logo src={logo} className="smart-touch__logo" alt="logo" />
            <Info>
                <Place>{location.mobilite}</Place><Date>{date}</Date>|<Time>{time}</Time>
            </Info>
            {bus ? 
                <React.Fragment>
                    <Ligne>
                        <LigneNumber>{bus.name}</LigneNumber>
                        <LignePlace>{bus.direction}</LignePlace>
                    </Ligne>
                    <TimeUntil>{bus.until} min</TimeUntil>
                </React.Fragment>
            : 
            <Error>
                <ErrorTitle>Donnée momentanement indisponible</ErrorTitle>
                <ErrorUrl>mobilitéit.lu</ErrorUrl>
            </Error>
            }
        </HeaderWrapper>
    )
}

export default Header;