import {
    all,
    call,
    delay,
    fork,
    put,
    race,
    select,
    take
} from "redux-saga/effects";
import {getApiUrl, getSApiUrl} from "../../config/helper";
import {getAffiches, getCustomer, getLocation} from "../../selectors";
import {
    AFFICHES_RECEIVED,
    AGENDA_RECEIVED,
    BUTTONS_RECEIVED,
    FETCH_ERROR,
    MOBILITE_RECEIVED,
    NEWS_RECEIVED,
    POLL_START,
    POLL_STOP
} from "../actions";

function* fetchAffiches() {
    const apiUrl = getApiUrl();
    const cAffiches = yield select(getAffiches);
    const customer = yield select(getCustomer);

    let url;
    if (customer === 'publismart') {
        url = `${apiUrl}api/export/forms/e267741f-bc9f-4cc5-bb65-1e0e46675dcd/json`;
    } 
    else if(customer === 'ngdemo'){
        url = `config/test.json`;
    }
    else {
        url = `${apiUrl}api/export/forms/3cc1308d-c5d2-4b10-9de4-e1b7ab0f84e5/json`;
    }

    try {
        const affiches = yield fetch(url).then((response) => response.json());
        //console.log(affiches);
        if (JSON.stringify(affiches) !== JSON.stringify(cAffiches)) {
            yield put({ type: AFFICHES_RECEIVED, affiches: affiches });
            console.log("test ", affiches)
        }
    } catch (error) {
        yield put({ type: FETCH_ERROR, error });
    }
}

function* fetchButtons() {
    const apiUrl = getApiUrl();
    const customer = yield select(getCustomer);

    //const blacklist = ['diekirch', 'erpeldange', 'rumelange'];
    //if (blacklist.includes(customer)) return false;

    try {
        const buttons = yield fetch(
            `${apiUrl}api/export/forms/b487d720-a515-4283-a0b8-be8c6771f80a/json`
        ).then((response) => response.json());
        if (buttons && buttons.length > 0) {
            if (buttons[0][customer] === true) {
                yield put({ type: BUTTONS_RECEIVED, buttons: buttons });
            } else {
                yield put({ type: BUTTONS_RECEIVED, buttons: [] });
            }
        }
    } catch (error) {
        yield put({ type: FETCH_ERROR, error });
    }
}

function* fetchNews() {
    const customer = yield select(getCustomer);
    const apiUrl = getSApiUrl();

    try {
        const news = yield fetch(`${apiUrl}/news2?city=${customer}`).then(
            (response) => response.json()
        );

        yield put({ type: NEWS_RECEIVED, news: news });
    } catch (error) {
        yield put({ type: FETCH_ERROR, error });
    }
}

function* fetchMobilite() {
    const apiUrl = getSApiUrl();
    const location = yield select(getLocation);
    const customer = yield select(getCustomer);

    try {
        const mobilite = yield fetch(
            `${apiUrl}/bus?customer=${customer}&language=fr`
        ).then((response) => response.json());

        /*
        let mobi = mobilite.stations.filter(
            (item) => item.name === location.mobilite
        );
        if (mobi.length > 0) mobi = mobi[0];
        */

        const keys = Object.keys(mobilite);
        let mobi;

        keys.forEach(key => {
            if (mobilite[key].name === location.mobilite) mobi = mobilite[key];
        });

        yield put({ type: MOBILITE_RECEIVED, mobilite: mobi });
    } catch (error) {
        yield put({ type: FETCH_ERROR, error });
    }
}

function* fetchAgenda() {
    const apiUrl = getSApiUrl();
    const customer = yield select(getCustomer);

    try {
        const agenda = yield fetch(`${apiUrl}/events2?city=${customer}`).then(
            (response) => response.json()
        );

        yield put({ type: AGENDA_RECEIVED, agenda: agenda });
    } catch (error) {
        yield put({ type: FETCH_ERROR, error });
    }
}

function* fetchData() {
    while (true) {
        try {
            yield all([
                fork(fetchAffiches),
                fork(fetchButtons),
                fork(fetchNews),
                fork(fetchMobilite),
                fork(fetchAgenda),
            ]);
        } catch (e) {
            // TODO: call error action
        }
        yield delay(60000); // MS 60000 -> 1min
    }
}

function* fetchDataWatcher() {
    while (true) {
        yield take(POLL_START);
        yield race([call(fetchData), take(POLL_STOP)]);
    }
}

export default fetchDataWatcher;
